// @see https://tailwindcss.com/docs/functions-and-directives#screen
// @config "./tailwind.config.js";

@tailwind base;
@tailwind components;
@tailwind utilities;

$headerMarginTopXs: 4;
$headerMarginTopMd: 7;
$headerMarginTopLg: 9;

$headerHeightXs: 16;
$headerHeightMd: 24;
$headerHeightLg: 32;

$triangleWidth: 736px;
$triangleHeight: 218px;

$boxIconSizeXs: 14.5;
$boxIconSizeMd: 22;
$boxIconSizeLg: 29;


// color: var(--text) !important;
body {

  &.mode-debug:before {
    content: 'xs';
    @apply fixed top-0 left-0 block px-2 py-1 bg-white shadow z-50;

    @media (min-width: theme('screens.sm')) {
      content: 'sm';
    }
    @media (min-width: theme('screens.md')) {
      content: 'md';
    }
    @media (min-width: theme('screens.lg')) {
      content: 'lg';
    }
    @media (min-width: theme('screens.xl')) {
      content: 'xl';
    }
    @media (min-width: theme('screens.2xl')) {
      content: '2xl';
    }
  }

  strong {
    @apply font-medium;
  }

  a {
    color: #0c0c0c;
    @apply underline;

    &:hover {
      color: #0c0c0c;
    }
  }

  &.theme-main {
    color: #0c0c0c;
    font-family: "Ubuntu", sans-serif;
    background-position: center top;
    background-image: var(--site-background-image);
    background-color: var(--site-background-color);
    background-size: var(--site-background-size);
    @apply text-base lg:text-lg font-light;

    &.bg-custom {
      @apply bg-no-repeat bg-fixed;
    }

    .page-container {
      @apply container mx-auto xl:max-w-5xl px-3;
    }

    .page {

      @apply overflow-hidden;

      &.bg-gradient {
        background: url("/resources/images/gradient.png") repeat-x center top;
      }
    }

    header {
      @apply flex flex-col sm:flex-row items-center lg:items-stretch space-y-3 sm:space-y-0 sm:space-x-12;
      @apply mt-#{$headerMarginTopXs} md:mt-#{$headerMarginTopMd} lg:mt-#{$headerMarginTopLg};
      @apply sm:h-#{$headerHeightXs} md:h-#{$headerHeightMd} lg:h-#{$headerHeightLg};

      .logo {
        @apply flex-grow flex items-center no-underline;

        img {
          @apply max-h-#{$headerHeightXs} md:max-h-#{$headerHeightMd} lg:max-h-#{$headerHeightLg} max-w-72;
        }

        span {
          @apply font-medium text-4xl lg:text-5xl ;
          color: var(--logo-span-color);
        }
      }

      .contact {
        @apply flex-none relative flex items-center space-x-2;

        &.bg-triangle:before {
          content: "";
          background: url("/resources/images/triangle.png") no-repeat center top;
          @apply hidden lg:block absolute -top-#{$headerMarginTopLg} left-1/2 -z-10;

          width: $triangleWidth;
          height: $triangleHeight;
          margin-left: calc(-1 * ($triangleWidth / 2));
        }

        a {

          @apply no-underline;

          &.phone {

            color: var(--header-phone-color);
            @apply font-medium;

            i.fa {
              @apply mr-2 mt-1 inline-block opacity-75;
              color: rgba(var(--header-phone-color));
            }

            span {
              @apply font-light;
            }
          }

          &.facebook, &.instagram {
            @apply text-3xl;
          }

          &.facebook {
            color: #4267B2;
          }

          &.instagram {
            color: #C13584;
          }

        }

      }
    }

    .page-section {
      @apply mb-8 md:mb-10 lg:mb-18;
    }

    article.main-article {

      h1 {
        @apply font-light text-center my-8 lg:my-11 text-4xl lg:text-4.25xl;
        color: var(--prose-h1-color);
      }
    }

    article {
      .description,
      .description-bottom {
        @apply prose max-w-none;

        color: var(--prose-text-color);

        h1 {
          color: var(--prose-h1-color);
        }

        h2 {
          color: var(--prose-h2-color);
        }

        h3 {
          color: var(--prose-h3-color);
        }

        a {
          color: var(--prose-link-color);
          @apply font-light;
        }

        strong {
          @apply text-inherit;
        }

        p {
          @apply text-justify
        }

        mark {
          &.marker-yellow {
            background-color: #FDFD77;
          }
          &.marker-green {
            background-color: #62F962;
          }
          &.marker-pink {
            background-color: #FC7899;
          }
          &.marker-blue {
            background-color: #72CCFD;
          }
          &.pen-red {
            background-color: transparent;
            color: #E71313;
          }
          &.pen-green {
            background-color: transparent;
            color: #128A00;
          }
        }

      }
    }

    section.images {
      @apply flex flex-wrap flex-row gap-7 justify-center;

      .image {
        @apply basis-1/2-gap-7 md:basis-1/3-gap-7 lg:basis-1/4-gap-7 content-center h-full text-center;
        aspect-ratio: var(--images-frame-aspect-ratio);
        padding: var(--images-frame-padding);
        background-color: var(--images-frame-background-color);

        a {
          @apply block h-full w-full flex items-center justify-center;
          img {
            @apply mx-auto max-w-full max-h-full;
          }
        }
      }
    }

    article.bottom-article {
      @apply mt-8 lg:mt-11 ;
    }

    .google-map-container {
      iframe {
        width: 100% !important;
      }
    }

    .boxes {
      @apply flex flex-wrap flex-row gap-x-7 gap-y-7 md:gap-y-10 justify-center;


      &.cols-2 {
        .box {
          @apply md:basis-1/2-gap-7;
        }
      }

      &.cols-3 {
        .box {
          @apply md:basis-1/2-gap-7 lg:basis-1/3-gap-7;
        }
      }

      .box {
        @apply relative text-center;
        @apply flex flex-col w-full;
        @apply mt-#{calc($boxIconSizeXs/2)} md:mt-#{calc($boxIconSizeMd/2)} lg:mt-#{calc($boxIconSizeLg/2)};
        @apply pt-12 md:pt-17 lg:pt-21.5 px-7.5 md:px-10 lg:px-15 pb-7.5 md:pb-10 lg:pb-15;
        //@apply bg-gradient-to-t from-gradient-boxBg-1 to-gradient-boxBg-2;
        background-color: var(--box-background-color);

        .icon {
          @apply w-#{$boxIconSizeXs} md:w-#{$boxIconSizeMd} lg:w-#{$boxIconSizeLg};
          @apply h-#{$boxIconSizeXs} md:h-#{$boxIconSizeMd} lg:h-#{$boxIconSizeLg};
          @apply -top-#{calc($boxIconSizeXs/2)} md:-top-#{calc($boxIconSizeMd/2)} lg:-top-#{calc($boxIconSizeLg/2)};
          @apply -ml-#{calc($boxIconSizeXs/2)} md:-ml-#{calc($boxIconSizeMd/2)} lg:-ml-#{calc($boxIconSizeLg/2)};
          @apply leading-#{$boxIconSizeXs} md:leading-#{$boxIconSizeMd} lg:leading-#{$boxIconSizeLg};
          @apply absolute text-center left-1/2;
          color: #afafaf;
          @apply rounded-full;
          @apply bg-gradient-to-b from-gradient-boxIcon-1 to-gradient-boxIcon-2;

          .fa {
            @apply text-3xl md:text-4xl lg:text-4.5xl;
            @apply leading-#{$boxIconSizeXs} md:leading-#{$boxIconSizeMd} lg:leading-#{$boxIconSizeLg};
          }

        }

        .name {
          @apply font-medium text-xl lg:text-2xl mb-4 lg:mb-5;
          color: var(--box-heading-color);
        }

        .description {
          @apply text-sm lg:text-base flex-grow;
          color: var(--box-text-color);
        }

        .btn {
          @apply inline-block text-sm lg:text-base border-0 no-underline mt-9 rounded-full;
          @apply py-1.5 pl-6 pr-4;
          color: #ffffff;
          background-color: var(--box-btn-background-color);

          i {
            @apply inline-block;
            font-style: normal;
            margin-left: 14px;
          }
        }

      }
    }


    footer {
      color: #999999;

      @apply bg-gradient-to-t from-gradient-footer-1 to-gradient-footer-2;
      @apply mt-10;

      a {
        @apply text-inherit no-underline;

        &:hover {
          @apply text-inherit underline;
        }
      }

      .footer-content {
        @apply py-5 md:py-0 md:h-37 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-11 items-center;

        .contact {
          @apply flex flex-col space-y-1 items-center md:items-start;

          & > span {
            @apply flex space-x-2 items-center;
          }
        }

        .socials {
          @apply flex flex-grow justify-end space-x-2;

          a {
            @apply text-3xl md:text-4xl ;

            &.facebook {
              color: #4267B2;
            }

            &.instagram {
              color: #C13584;
            }
          }
        }
      }
    }

  }
}